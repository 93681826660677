import "../Styles/index.scss";

import "@ungap/custom-elements";
import $ from "jquery";
import { Fancybox } from "@fancyapps/ui";
import "./jquery.ui.effect.js";
import component from "svelte-tag";

const colors = ["#c41001", "#51bef7", "#005200", "#ffc44e", "#9ff733", "#293569", "#df45ff", "#ff025f", "#006fa6", "#f27501", "#8a1259", "#90da00", "#deff1c", "#560027", "#8effaf", "#007b44", "#008e72", "#ff6245", "#6e8fb2", "#001b76"];
const images = JSON.parse(document.getElementById("jsonImages")?.innerText ?? "[]");
$(".color-fields__field").each(function (i, el) {
	$(el).css('backgroundColor', colors[i]);
	animateToColor($(el), i, colors);
});

Fancybox.bind("a.image, a:has(.thumbnail), [data-fancybox]", {});

$(".button-menu").click(function () {
	$(".main-navigation-wrapper").toggleClass("is-open");
});

if (document.querySelector("bluc-map") != null) {
	import("./bluc-map.svelte").then(m => new component({
		component: m.default,
		tagname: "bluc-map",
		attributes: ["longitude", "latitude", "zoom", "accesstoken", "style"]
	}));
}

if (document.querySelector("seasontickets-form") != null) {
	import("./seasontickets-form.svelte").then(m => new component({
		component: m.default,
		tagname: "seasontickets-form",
	}));
}

if (document.querySelector("contact-form") != null) {
	import("./contact-form.svelte").then(m => new component({
		component: m.default,
		tagname: "contact-form",
	}));
}

function animateToColor(element: JQuery, index: number, colors: string[]) {
	const imageClass = "color-fields__image";
	var newIndex = index + 1;
	if (newIndex == colors.length) newIndex = 0;

	var random = randomInteger(0, 8);
	if (element.data("image") != false && random == 5 && images.length > 0) {
		var randomImageIndex = randomInteger(0, images.length - 1);
		var image = $("<img/>").addClass(imageClass).attr("src", images[randomImageIndex]);
		image.hide().appendTo(element);

		image.fadeIn(randomInteger(2500, 5000), function () {
			image.fadeOut(randomInteger(2500, 5000), function () {
				image.remove();
				animateToColor(element, newIndex, colors);
			});
		});
	} else {
		element.animate({ backgroundColor: colors[index] }, randomInteger(2500, 5000), function (this: Element) {
			animateToColor(element, newIndex, colors);
		});
	}
}

function randomInteger(min: number, max: number) {
	if (min > max) return (-1);
	if (min == max) return (min);
	return (min + parseInt((Math.random() * (max - min + 1)).toString(), 10));
}
